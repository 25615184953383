<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800px"
    style="z-index:200000001;"
  >
    <v-card>
      <v-card-title class="d-flex flex-row justify-between app-bold-font">
        Availability
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="app-regular-font">
        What's days and times you are available
      </v-card-text>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="data"
          :loading="loading"
          class="elevation-0"
          hide-default-footer
        >
          <template v-slot:[`item.morning`]="{ item }">
            <div class="d-flex justify-center">
              <v-checkbox
                v-model="item.morning"
                @change="onUpdateValue(item, 'morning')"
              />
            </div>
          </template>
          <template v-slot:[`item.afternoon`]="{ item }">
            <div class="d-flex justify-center">
              <v-checkbox
                v-model="item.afternoon"
                @change="onUpdateValue(item, 'afternoon')"
              />
            </div>
          </template>
          <template v-slot:[`item.evening`]="{ item }">
            <div class="d-flex justify-center">
              <v-checkbox
                v-model="item.evening"
                @change="onUpdateValue(item, 'evening')"
              />
            </div>
          </template>
          <template v-slot:[`item.nothing`]="{ item }">
            <div class="d-flex justify-center">
              <v-checkbox
                v-model="item.nothing"
                @change="onUpdateValue(item, 'nothing')"
              />
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions
        style="display: flex; flex-direction: row; justify-content:center;"
        class="pb-6 mt-3"
      >
        <v-btn color="#b5e539" @click="onSave" width="150" :loading="loading">
          Continue
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function
    }
  },
  data() {
    return {
      message: null,
      loading: false,
      headers: [
        { text: "", value: "day", sortable: false },
        { text: "Morning", value: "morning", sortable: false, align: "center" },
        {
          text: "Afternoon",
          value: "afternoon",
          sortable: false,
          align: "center"
        },
        { text: "Evening", value: "evening", sortable: false, align: "center" },
        {
          text: "Don't schedule me",
          value: "nothing",
          sortable: false,
          align: "center"
        }
      ],
      data: [
        {
          day: "Mon",
          morning: false,
          afternoon: false,
          evening: false,
          nothing: false
        },
        {
          day: "Tue",
          morning: false,
          afternoon: false,
          evening: false,
          nothing: false
        },
        {
          day: "Wed",
          morning: false,
          afternoon: false,
          evening: false,
          nothing: false
        },
        {
          day: "Thu",
          morning: false,
          afternoon: false,
          evening: false,
          nothing: false
        },
        {
          day: "Fri",
          morning: false,
          afternoon: false,
          evening: false,
          nothing: false
        },
        {
          day: "Sat",
          morning: false,
          afternoon: false,
          evening: false,
          nothing: false
        },
        {
          day: "Sun",
          morning: false,
          afternoon: false,
          evening: false,
          nothing: false
        }
      ]
    };
  },
  methods: {
    ...mapActions("auth", {
      updateProfile: "updateProfile"
    }),
    onUpdateValue(item, key) {
      console.log(item);
      this.message = null;
      if (key == "nothing" && item[key]) {
        item.morning = false;
        item.afternoon = false;
        item.evening = false;
      } else if (item[key]) {
        item.nothing = false;
      }
    },
    onSave() {
      let ok = false;
      let data = [];
      for (let i = 0; i < this.data.length; i++) {
        if (
          this.data[i].morning ||
          this.data[i].afternoon ||
          this.data[i].evening
        ) {
          ok = true;
          data.push(this.data[i]);
        } else if (this.data[i].nothing) {
          data.push(this.data[i]);
        }
      }
      if (!ok) {
        this.message = "Please select at least one.";
        return;
      }
      this.loading = true;
      this.updateProfile({ availabilities: data })
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch(error => {
          this.loading = false;
          this.message = error.response.data.message;
        });
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile"
    })
  },
  mounted() {
    if (this.profile && this.profile.availabilities) {
      for (let i = 0; i < this.profile.availabilities.length; i++) {
        for (let j = 0; j < this.data.length; j++) {
          if (this.profile.availabilities[i].day == this.data[j].day) {
            this.data[j] = { ...this.profile.availabilities[i] };
            console.log(this.data[j]);
          } else {
            console.log(this.profile.availabilities[i].day, this.data[j].day);
          }
        }
      }
      this.data = [...this.data];
    }
  }
};
</script>
<style scoped>
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
    /* padding-top: 17px; */
    /* padding-bottom: 17px; */
    /* height: 100%; */
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
  div :deep(.v-dialog) {
    /* height: 80vh; */
    width: 70%;
    max-width: 900px;
  }
}
.time-picker {
  width: 107px;
  border-color: #d094ea !important;
  border-width: 1px;
  height: 40px;
}
.time-picker :deep(input.display-time) {
  height: 40px !important;
  width: 100%;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.38);
  padding: 0 15px;
}
.time-picker :deep(input.display-time:hover) {
  border-color: currentColor;
}
.time-picker :deep(input.display-time:focus-visible) {
  border-color: #d094ea !important;
  border-width: 1px;
}
.time-picker :deep(.clear-btn) {
  padding: 5px 16px;
  margin-top: 5px;
}
.v-dialog__content {
  z-index: 999999999 !important;
}
</style>
